import axios from 'axios';
import { toast } from 'react-toastify';
import useAuth from '../hooks/useAuth';

console.log(process.env);

const axiosServices = axios.create({
    // baseURL: process.env.REACT_APP_BASE_URL || 'http://localhost:3030',
    // baseURL: process.env.REACT_APP_BASE_URL || 'https://backend.owowtalents.com',
    // baseURL: process.env.REACT_APP_BASE_URL || 'https://api.owowtalents.com',
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        common: {
            Authorization: ''
        },
        post: {
            'Content-Type': 'application/json'
        }
    }
});

// interceptor for http
axiosServices.interceptors.response.use(
    (response: any) => {
        // console.log("response");
        // console.log(response);
        const { success, message = null, code = null } = response.data;
        if (success && message) {
            if (code !== 100) toast.success(message, { theme: 'colored' });
        } else if (!success && message) {
            toast.error(message, { theme: 'colored' });

            if (code === 995 || code === 998 || code === 999) {
                const { logout } = useAuth();
                logout();
            }
        }
        return response;
    },
    (error: any) => {
        console.log("error");
        console.log(error);
        let msg = error.response?.data?.message;

        // if (msg[0]) {
        //     msg = msg[0];
        // }

        let error_msg = msg || 'Failed to Connect Server! Check your internet connection';

        // if (error.response && error.response.status) {
        //     switch (error.response.status) {
        //         case 400:
        //             error_msg = 'Request seems to be Broken';
        //             break;
        //         case 401:
        //             error_msg = 'User Details Not Found';
        //             break;
        //         case 403:
        //             error_msg = 'Forbidden Request';
        //             break;
        //         case 406:
        //             error_msg = 'Token Seems to Manipulated';
        //             break;
        //         case 500:
        //             error_msg = 'Invalid URL';
        //     }
        // }
        // if (!msg[0]) {
        if (error_msg !== 'Unauthorized') toast.error(error_msg, { theme: 'colored' });
        // }

        return Promise.reject(error_msg);
    }
);

export default axiosServices;
