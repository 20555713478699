import React, { useEffect, useState } from 'react'
import Logo_Horizontal_purpleImage from '../../Assets/Icons/Logo_Horizontal_purple.png'
import RightSideArrowIcon from '../../Assets/Icons/maki_arrow.png'
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';


const GPTVettingLetsStartedPage = () => {
    const location = useLocation();
    const { user } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!location.state || !location.state.skill || !location.state.exp) {
            navigate('/candidate-gpt-vetting', { replace: true });
        }
        setLoading(false);
    }, [location.state]);

    const startTest = () => {
        navigate(`/candidate-gpttest-mcqs`, { state: location.state });
    };

    return (
        <section style={{ background: "#F1EBFF",minHeight: "100vh" }}>
            <div className='container'>
                <div className='row'>
                    {loading ? <h3>Loading...</h3> : (
                        <div className='col-sm-8 col-12 mx-auto mb-4'>
                            <div className='d-flex justify-content-between mt-4 mb-3'>
                                <div>
                                    <img src={Logo_Horizontal_purpleImage} />
                                </div>
                                <div style={{ color: "#BCBCBC" }}>
                                    <h5>Gen AI Vetting</h5>
                                    <h6>powered by OWOW</h6>
                                </div>
                            </div>
                            <div className='card cst-Lets-get-started p-4'>
                                <div>
                                    <h4 className='cst-primary-color'>Let’s get started</h4>
                                    <p className='text-black'>This screening will cover your technical skills for the role of <b>‘{location.state.skill}’</b></p>
                                    <div className='d-flex flex-wrap mt-2' style={{ gap: "12px" }}>
                                        <button className='btn btn-blue-btn-tag'>10 Questions</button>
                                        <button className='btn btn-blue-btn-tag'>1 minute per question</button>
                                        <button className='btn btn-blue-btn-tag'>Approx 10 mins long</button>
                                    </div>
                                </div>
                                <hr />
                                <div className='list-of-numbers-text'>
                                    <ul>
                                        <li>This is an AI based Interview avoid using old or slow devices.</li>
                                        <li>Ensure a stable internet connection for seamless communication.</li>
                                    </ul>
                                </div>
                                <div className='card cst-processing-steps-card p-4'>
                                    <div className='d-flex flex-wrap' style={{ gap: "12px" }}>
                                        <h5 className='cst-primary-color'><b> Our proctoring steps for ensuring a violation-free screening</b></h5>
                                    </div>
                                    <div className='list-of-numbers-text'>
                                        <ul>
                                            <li>Ensures there are no multiple screens connected to your primary device.</li>
                                            <li>we are tracking refreshes, change of tabs or window during the screening</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-end mt-4'>
                                    <button
                                        className='btn start-test-button'
                                        onClick={() => startTest()}
                                    >Continue <img src={RightSideArrowIcon} className='img-fluid' /></button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}

export default GPTVettingLetsStartedPage