import { useLocation, useNavigate } from 'react-router-dom';
import Logo_Horizontal_purpleImage from '../../Assets/Icons/Logo_Horizontal_purple.png'
import { useEffect } from 'react';

const OwowVettingGreatJobsPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!location.state || !location.state.result) navigate('/candidate-gpt-vetting', { replace: true });
    }, [location.state]);

    return (
        <>
            <section style={{ background: "#F1EBFF", height: "100vh" }}>
                <div className='container'>
                    <div className='row  pt-5'>
                        <div className='col-sm-8 col-12 mx-auto mb-4'>
                            <div className='card cst-Lets-get-started p-4'>
                                <div className='d-flex justify-content-center mb-4'>
                                    <img src={Logo_Horizontal_purpleImage} />
                                </div>

                                <h3 className='cst-primary-color text-center mb-2'>Great Job!!</h3>

                                <h5 className="text-center">
                                    Congratulations on completing the vetting process!<br/>
                                    Your dedication and commitment have led you through a rigorous evaluation, and your achievement reflects your hard work and talent. We're thrilled to have you onboard, and we look forward to seeing your contributions to our team.<br/>
                                    Welcome aboard, and congratulations once again!
                                </h5>

                                <div className='d-flex justify-content-center mt-4'>
                                    <button
                                        className='btn start-test-button'
                                        onClick={() => navigate('/candidate-gpt-vetting', { replace: true })}
                                    >Done</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default OwowVettingGreatJobsPage